var mena = window.mena || {};

('use strict');

mena.main.init = function () {
    mena.main.mobileMenu();
    mena.main.svg4everybody();
    mena.main.loadAssets();
    mena.main.accordion();
    mena.main.toggleSearch();
    mena.main.getLatestPosts();
    mena.main.menuMorph();
    mena.main.updateQty();
    mena.main.toggleForm();
    mena.main.singleProductQuantity();
    mena.main.loadProductsByTag();
    mena.main.loadDanceNewsByTopic();
    mena.main.sidebarTag();
    mena.main.delAnnounces();
    mena.main.setPositionForSingleScrollBtn();
};

mena.main.loadAssets = function () {
    if (jQuery('.catalog__items').length > 0 || jQuery('.authors__items').length > 0 || jQuery('.dance-news__items').length > 0 || jQuery('.products__items').length > 0 || jQuery('.number-text__items').length) {
        loadjs([menaObject.assets + '/js/swiper.min.js', menaObject.assets + '/css/swiper.css'], function () {
            mena.main.catalogSlider();
            mena.main.authorsSlider();
            mena.main.danceNewsSlider();
            mena.main.productsSlider();
            mena.main.numberTextSlider();
        });
    }
};

mena.main.setPositionForSingleScrollBtn = function () {
    const $scrollBtn = jQuery('.single-post__scroll-btn');
    if ($scrollBtn.length === 0) return;

    changePosition();
    jQuery(window).on('resize', changePosition);

    function changePosition() {
        const $intro = jQuery('.single-post-intro');
        const rightPosition = jQuery(window).width() - $intro.offset().left - $intro.outerWidth();
        $scrollBtn.css('right', rightPosition);
    }

    $scrollBtn.on('click', function () {
        $scrollBtn.addClass('hidden');
    });
};

mena.main.sidebarTag = function () {
    jQuery('.sidebar__tag').on('click', function () {
        window.location = jQuery(this).data('archive') + '?tag_filter=' + jQuery(this).data('tag');
    });
};

mena.main.loadDanceNewsByTopic = function () {
    if (jQuery('body').hasClass('page-template-dance-news-archive')) {
        let url = new URL(String(window.location.href));
        let search = url.searchParams.get('search');
        if (search) {
            scrollToTop();
        }

        let topic = url.searchParams.get('topic');

        if (topic) {
            jQuery.ajax({
                type: 'POST',
                url: menaObject.ajaxurl,
                dataType: 'html',
                data: {
                    action: 'get_dance_news_by_tag',
                    paginationNumber: 1,
                    term: topic,
                },

                beforeSend: function () {
                    jQuery('.js-posts-wrapper').addClass('loading');
                },

                success: function (response) {
                    jQuery('.js-posts-wrapper').html(response);
                    jQuery('.js-posts-wrapper').removeClass('loading');

                    scrollToTop();
                },
            });
        }

        function scrollToTop() {
            jQuery([document.documentElement, document.body]).animate(
                {
                    scrollTop: jQuery('.js-posts-wrapper').offset().top - 64,
                },
                0,
            );
        }
    }
};

mena.main.loadProductsByTag = function () {
    if (jQuery('body').hasClass('tax-product_tag') || jQuery('body').hasClass('woocommerce-shop')) {
        let term = false;

        if (jQuery('body').hasClass('woocommerce-shop')) {
            var url = new URL(String(window.location.href));
            term = url.searchParams.get('ptag');
        } else {
            term = String(window.location.href)
                .split('/')
                .filter(function (string) {
                    return string;
                })
                .at(-1);
        }
        if (term) {
            jQuery.ajax({
                type: 'POST',
                url: menaObject.ajaxurl,
                dataType: 'html',
                data: {
                    action: 'get_products_by_tag',
                    paginationNumber: 1,
                    term: term,
                },

                beforeSend: function () {
                    jQuery('.js-posts-wrapper').addClass('loading');
                },

                success: function (response) {
                    jQuery('.js-posts-wrapper').html(response);
                    jQuery('.js-posts-wrapper').removeClass('loading');

                    jQuery([document.documentElement, document.body]).animate(
                        {
                            scrollTop: jQuery('.js-posts-wrapper').offset().top - 64,
                        },
                        0,
                    );
                },
            });
        }
    }
};

mena.main.singleProductQuantity = function () {
    jQuery('.single-product .quantity button').on('click', function () {
        var minValue = jQuery('.single-product .quantity input[type="number"]').attr('min');
        var maxValue = jQuery('.single-product .quantity input[type="number"]').attr('max');
        var currentValue = jQuery('.single-product .quantity input[type="number"]').val();

        if (jQuery(this).hasClass('minus')) {
            if (currentValue > minValue) {
                jQuery('.single-product .quantity input[type="number"]').val(Number(currentValue) - 1);
            }
        } else if (jQuery(this).hasClass('plus')) {
            if (maxValue) {
                if (currentValue < maxValue) {
                    jQuery('.single-product .quantity input[type="number"]').val(Number(currentValue) + 1);
                }
            } else {
                jQuery('.single-product .quantity input[type="number"]').val(Number(currentValue) + 1);
            }
        }
    });
};

mena.main.menuMorph = function () {
    jQuery('#resp-main-nav .menu-item > a').hover(
        function () {
            jQuery(this).removeClass('out').addClass('over');
        },
        function () {
            var item = jQuery(this);

            setTimeout(function () {
                jQuery(item).removeClass('over').addClass('out');
            }, 150);
        },
    );

    jQuery('.bookshop-nav__cta').hover(
        function () {
            jQuery(this).removeClass('out').addClass('over');
        },
        function () {
            var item = jQuery(this);

            setTimeout(function () {
                jQuery(item).removeClass('over').addClass('out');
            }, 150);
        },
    );
};

mena.main.svg4everybody = function () {
    svg4everybody();
};

mena.main.mailchimp = function () {
    if (jQuery('.js-mailchimp').length > 0) {
        loadjs(menaObject.assets + '/js/mailchimp-validate.js', function () {
            window.fnames = new Array();
            window.ftypes = new Array();
            fnames[0] = 'EMAIL';
            ftypes[0] = 'email';
        });
    }
};

mena.main.catalogSlider = function () {
    const swiper = new Swiper('.catalog__items', {
        slidesPerView: 1,
        spaceBetween: 16,
        navigation: {
            nextEl: '.catalog__next',
            prevEl: '.catalog__prev',
        },
        scrollbar: {
            el: '.catalog__scrollbar',
        },
        breakpoints: {
            // when window width is >= 440px
            450: {
                slidesPerView: 2,
            },
            770: {
                slidesPerView: 4,
                allowTouchMove: false,
            },
        },
    });
};

mena.main.authorsSlider = function () {
    const swiper = new Swiper('.authors__items', {
        slidesPerView: 1,
        grabCursor: true,
        spaceBetween: 16,
        navigation: {
            nextEl: '.authors__next',
            prevEl: '.authors__prev',
        },
        scrollbar: {
            el: '.authors__scrollbar',
        },
        breakpoints: {
            // when window width is >= 450px
            450: {
                slidesPerView: 2,
            },
            // when window width is >= 640px
            770: {
                slidesPerView: 3,
            },
            // when window width is >= 1024px
            1024: {
                slidesPerView: 4,
            },
        },
    });
};

mena.main.danceNewsSlider = function () {
    const swiper = new Swiper('.dance-news__items', {
        slidesPerView: 2,
        spaceBetween: 18,
        grabCursor: true,
        navigation: {
            nextEl: '.dance-news__next',
            prevEl: '.dance-news__prev',
        },
        scrollbar: {
            el: '.dance-news__scrollbar',
        },
        breakpoints: {
            // when window width is >= 450px
            450: {
                slidesPerView: 2,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 3,
            },
            // when window width is >= 770px
            770: {
                slidesPerView: 4,
            },
            // when window width is >= 1024px
            1024: {
                slidesPerView: 5,
                grabCursor: false,
            },
        },
    });
};

mena.main.productsSlider = function () {
    const swiper = new Swiper('.products__items', {
        slidesPerView: 2,
        spaceBetween: 18,
        grabCursor: true,
        navigation: {
            nextEl: '.products__next',
            prevEl: '.products__prev',
        },
        scrollbar: {
            el: '.products__scrollbar',
        },
        breakpoints: {
            // when window width is >= 450px
            450: {
                slidesPerView: 2,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 3,
            },
            // when window width is >= 770px
            770: {
                slidesPerView: 4,
            },
            // when window width is >= 1024px
            1024: {
                slidesPerView: 5,
                grabCursor: false,
            },
        },
    });
};

mena.main.numberTextSlider = function () {
    const swiper = new Swiper('.number-text__items', {
        slidesPerView: 1,
        spaceBetween: 16,
        slidesPerGroup: 1,
        grabCursor: true,
        navigation: {
            nextEl: '.number-text__next',
            prevEl: '.number-text__prev',
        },
        scrollbar: {
            el: '.number-text__scrollbar',
        },
        breakpoints: {
            // when window width is >= 450px
            450: {
                slidesPerGroup: 2,
                slidesPerView: 2,
            },
            // when window width is >= 640px
            640: {
                slidesPerGroup: 3,
                slidesPerView: 3,
            },
        },
    });
};

mena.main.debounce = function (func, wait, immediate) {
    var timeout;
    return function () {
        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

mena.main.stickyHeader = function () {
    var stickyHeader = function () {
        var header = jQuery('#header');
        jQuery('#content').css('margin-top', header.outerHeight());

        if (jQuery(window).scrollTop() > 1) {
            header.addClass('sticky');
        } else {
            header.removeClass('sticky');
        }
    };

    window.addEventListener('load', stickyHeader);
    window.addEventListener('scroll', mena.main.debounce(stickyHeader, 150));
    window.addEventListener('resize', mena.main.debounce(stickyHeader, 150));
};

mena.main.mobileMenu = function () {
    var respMenu = jQuery('.resp-menu');
    var button = jQuery('.js-menu-toggle');
    var body = jQuery('body');

    jQuery('.resp-buttons,.resp-menu__overlay').click(function (e) {
        body.toggleClass('menu-open');
        button.toggleClass('menu-toggle--active');
    });

    /* Sliding side menu, close on swipe */
    if (jQuery().swipe) {
        respMenu.swipe({
            swipeRight: function (event, direction, distance, duration, fingerCount) {
                body.removeClass('menu-open');
            },
            threshold: 20,
            excludedElements: '',
        });
        button.swipe({
            swipeLeft: function (event, direction, distance, duration, fingerCount) {
                body.addClass('menu-open');
            },
            threshold: 20,
            excludedElements: '',
        });
    }

    /* Sliding side menu submenu */
    respMenu.find('.menu-item > a').click(function (e) {
        if (jQuery(this).attr('href') == '#') {
            e.preventDefault();
            jQuery('.sub-menu').not(jQuery(this).siblings()).slideUp(300);
            jQuery(this).siblings('.sub-menu').slideToggle(300);
        }
    });
};

mena.main.accordion = function () {
    jQuery('.accordion__subtitle').on('click', function () {
        if (jQuery(this).hasClass('active')) {
            jQuery(this).removeClass('active');
            jQuery(this).next('.accordion__text').slideToggle(400, 'swing');
        } else {
            jQuery(this).addClass('active');
            jQuery(this).next('.accordion__text').slideToggle(400, 'swing');
        }
    });
};

mena.main.toggleSearch = function () {
    var element;

    jQuery('.filters__search-wrapper .filters__search-svg').on('click', function () {
        element = jQuery(this).parent();

        if (!jQuery(element).hasClass('opened')) {
            jQuery(element).addClass('opened');
            jQuery(element).find('.filters__search-svg').addClass('scroll-bottom');

            setTimeout(function () {
                jQuery(element).find('.filters__search-svg').addClass('active');

                setTimeout(function () {
                    jQuery(element).find('.filters__search').addClass('active').focus();
                }, 300);
            }, 300);
        }
    });
};

mena.main.getLatestPosts = function () {
    let pageNumber, category, container, currentActionName, currentActionInfo, typingTimer, cpt, eventType, search;
    let searchInput = jQuery('.js-filter-search');
    let doneTypingInterval = 400;

    let currentURL = window.location.href;
    currentURL = new URL(currentURL);

    let filterParameter = currentURL.searchParams.get('tag_filter');

    listenPagination();

    if (filterParameter != null) {
        if (jQuery('body').hasClass('page-template-events-archive')) {
            getPosts(1, filterParameter, false, 'get_events_by_tag', jQuery('#post-wrapper-future'), false, 'future', false);
            getPosts(1, filterParameter, false, 'get_events_by_tag', jQuery('#post-wrapper-past'), false, 'past', false);
        }

        if (jQuery('body').hasClass('page-template-news-archive')) {
            getPosts(1, filterParameter, false, 'get_latest_news_by_tag', jQuery('.js-posts-wrapper'), false, false, true);
        }
    }

    jQuery('.js-posts .filters span').on('click', function (ev) {
        if (!jQuery(this).hasClass('active')) {
            if (jQuery(this).hasClass('js-term')) {
                jQuery('.js-posts .filters .filters__category.active').removeClass('active');
                jQuery('.js-posts .filters .js-term.active').removeClass('active');
                jQuery(this).addClass('active');

                category = jQuery(this).data('category');
                currentActionInfo = checkContainer(jQuery(this));
                cpt = jQuery('.js-posts .filters .filters__category.active').data('name');

                if (jQuery('.js-posts.js-posts-events-past').length > 0) {
                    eventType = 'past';
                    container = jQuery('.js-posts-events-past .js-posts-wrapper');
                    getPosts(1, category, false, 'get_events', container, cpt, eventType, false);
                }

                getPosts(1, category, false, currentActionInfo.name, currentActionInfo.container, cpt, currentActionInfo.eventType);
            }

            if (jQuery(this).hasClass('js-post-type')) {
                jQuery('.js-posts .filters .filters__category.active').removeClass('active');
                jQuery(this).addClass('active');

                category = jQuery(this).data('category');
                currentActionInfo = checkContainer(jQuery(this));
                cpt = jQuery('.js-posts .filters .filters__category.active').data('name');

                getPosts(1, category, false, currentActionInfo.name, currentActionInfo.container, cpt);
            }
        }
    });

    jQuery(searchInput).on('keyup input paste keypress', function (e) {
        var tempInput = jQuery(this);
        var keyCode = e.keyCode || e.which;

        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }

        clearTimeout(typingTimer);
        typingTimer = setTimeout(doneTyping, doneTypingInterval);

        function doneTyping() {
            var val = jQuery(searchInput).val().trim();

            if (val.length > 2 || val.length === 0) {
                let category = jQuery(tempInput).closest('.js-posts').find('.js-term.active').data('category');
                search = val;

                currentActionInfo = checkContainer(tempInput);
                cpt = jQuery('.js-posts .filters .filters__category.active').data('name');

                if (jQuery('.js-posts.js-posts-events-past').length > 0) {
                    eventType = 'past';
                    container = jQuery('.js-posts-events-past .js-posts-wrapper');
                    getPosts(1, category, search, 'get_events', container, cpt, eventType, false);
                }

                if (jQuery('body').hasClass('category')) {
                    category = jQuery('.js-posts-category').data('category');
                }

                getPosts(1, category, search, currentActionInfo.name, currentActionInfo.container, cpt, currentActionInfo.eventType);
            }
        }
    });

    jQuery('.filters__search-wrapper .filters__search-close').on('click', function () {
        let element = jQuery(this).closest('.filters__search-wrapper');

        currentActionInfo = checkContainer(element);
        cpt = jQuery('.js-posts .filters .filters__category.active').data('name');

        getPosts(1, category, false, currentActionInfo.name, currentActionInfo.container, cpt, currentActionInfo.eventType);

        if (jQuery(element).hasClass('opened')) {
            jQuery(element).find('.filters__search').removeClass('active');

            setTimeout(function () {
                jQuery(element).find('.filters__search-svg').removeClass('active');

                setTimeout(function () {
                    jQuery(element).removeClass('opened');
                    jQuery(element).find('.filters__search-svg').removeClass('scroll-bottom');
                }, 300);
            }, 300);
        }
    });

    function listenPagination() {
        jQuery('.js-posts:not(.js-posts-events-past)').on('click', '.page-numbers:not(.dots)', function (ev) {
            ev.preventDefault();

            if (!jQuery(this).hasClass('current')) {
                pageNumber = jQuery(this).text();

                if (jQuery(this).hasClass('next')) {
                    pageNumber = parseInt(jQuery(this).siblings('.current').text()) + 1;
                }

                if (jQuery(this).hasClass('prev')) {
                    pageNumber = parseInt(jQuery(this).siblings('.current').text()) - 1;
                }

                category = jQuery(this).closest('.js-posts').find('.js-term.active').data('category');
                container = jQuery(this).closest('.js-posts').find('.js-posts-wrapper');
                currentActionInfo = checkContainer(jQuery(this));
                cpt = jQuery('.js-posts .filters .filters__category.active').data('name');

                getPosts(pageNumber, category, search, currentActionInfo.name, currentActionInfo.container, cpt);
            }
        });

        jQuery('.js-posts-events-past').on('click', '.page-numbers:not(.dots)', function (ev) {
            ev.preventDefault();

            if (!jQuery(this).hasClass('current')) {
                pageNumber = jQuery(this).text();

                if (jQuery(this).hasClass('next')) {
                    pageNumber = parseInt(jQuery(this).siblings('.current').text()) + 1;
                }

                if (jQuery(this).hasClass('prev')) {
                    pageNumber = parseInt(jQuery(this).siblings('.current').text()) - 1;
                }

                category = jQuery('.js-posts .js-term.active').data('category');
                currentActionInfo = checkContainer(jQuery(this));

                getPosts(pageNumber, category, search, currentActionInfo.name, currentActionInfo.container, '', currentActionInfo.eventType);
            }
        });
    }

    function checkContainer(element) {
        let name, container;
        let eventType = 'future';
        let result = {};

        if (jQuery(element).closest('.js-posts').hasClass('js-posts-news')) {
            name = 'get_latest_news';
            container = jQuery(element).closest('.js-posts').find('.js-posts-wrapper');
        }

        if (jQuery(element).closest('.js-posts').hasClass('js-posts-announcements')) {
            name = 'get_latest_announcements';
            container = jQuery(element).closest('.js-posts').find('.js-posts-wrapper');
        }

        if (jQuery(element).closest('.js-posts').hasClass('js-posts-schedule')) {
            name = 'get_latest_schedules';
            container = jQuery(element).closest('.js-posts').find('.js-posts-wrapper');
        }

        if (jQuery(element).closest('.js-posts').hasClass('js-posts-authors')) {
            name = 'get_authors';
            container = jQuery(element).closest('.js-posts').find('.js-posts-wrapper');
        }

        if (jQuery(element).closest('.js-posts').hasClass('js-posts-dance-news')) {
            name = 'get_latest_dance_news';
            container = jQuery(element).closest('.js-posts').find('.js-posts-wrapper');
        }

        if (jQuery(element).closest('.js-posts').hasClass('js-posts-products')) {
            name = 'custom_get_products';
            container = jQuery(element).closest('.js-posts').find('.js-posts-wrapper');
        }

        if (jQuery(element).closest('.js-posts').hasClass('js-posts-events-future')) {
            name = 'get_events';
            container = jQuery('#post-wrapper-future');
        }

        if (jQuery(element).closest('.js-posts').hasClass('js-posts-events-past')) {
            name = 'get_events';
            container = jQuery('#post-wrapper-past');
            eventType = 'past';
        }

        if (jQuery(element).closest('body').hasClass('category')) {
            name = 'get_category_posts';
            container = jQuery('.js-posts-category');
            eventType = 'category';
        }

        result = {
            container: container,
            eventType: eventType,
            name: name,
        };

        return result;
    }

    function getPosts(paginationNumber, category, search, actionName, container, cpt, eventType = '', scroll = true) {
        let is_ndd = false;

        if (jQuery(container).closest('.ndd-post-archive').length > 0) {
            is_ndd = true;
        }

        jQuery.ajax({
            type: 'POST',
            url: menaObject.ajaxurl,
            dataType: 'html',
            data: {
                action: actionName,
                paginationNumber: paginationNumber,
                category: category,
                search: search,
                custom_post_type: cpt,
                event_type: eventType,
                is_ndd: is_ndd,
            },

            beforeSend: function () {
                jQuery(container).addClass('loading');
                console.log(actionName);
            },

            success: function (response) {
                jQuery(container).html(response);
                jQuery(container).removeClass('loading');

                if (actionName != 'get_events' && actionName != 'get_latest_dance_news') {
                    if (scroll) {
                        jQuery([document.documentElement, document.body]).animate(
                            {
                                scrollTop: jQuery(container).offset().top - 64,
                            },
                            0,
                        );
                    }
                }
            },
        });
    }
};

mena.main.updateQty = function () {
    function handleQty() {
        jQuery('form.woocommerce-cart-form button.plus, form.woocommerce-cart-form button.minus').on('click', function () {
            var qty = jQuery(this).siblings('.qty');
            var val = parseFloat(qty.val());
            var max = parseFloat(qty.attr('max'));
            var min = parseFloat(qty.attr('min'));
            var step = parseFloat(qty.attr('step'));

            if (jQuery(this).hasClass('plus')) {
                if (max && max <= val) {
                    qty.val(max);
                } else {
                    qty.val(val + step);
                    updateQty();
                }
            } else {
                if (min && min >= val) {
                    qty.val(min);
                } else if (val > 1) {
                    qty.val(val - step);
                    updateQty();
                }
            }
        });
    }

    handleQty();
    var timeout;

    function updateQty() {
        timeout = setTimeout(function () {
            jQuery('[name=update_cart]')
                .prop({
                    disabled: false,
                    'aria-disabled': false,
                })
                .trigger('click');
        }, 1000);
    }

    jQuery('.woocommerce').on('change', 'input.qty', updateQty);

    jQuery(document.body).on('updated_cart_totals', function () {
        handleQty();
    });
};

mena.main.toggleForm = function () {
    jQuery('.woo-announcements__button').on('click', function () {
        if (jQuery(this).hasClass('active')) {
            jQuery(this).removeClass('active');
            jQuery(this).next('.announces-form').slideToggle(400, 'swing');
        } else {
            jQuery(this).addClass('active');
            jQuery(this).next('.announces-form').slideToggle(400, 'swing');
        }
    });
};

mena.main.delAnnounces = function () {
    function deleteAnnounces(id, contentCard) {
        jQuery.ajax({
            type: 'POST',
            url: menaObject.ajaxurl,
            dataType: 'html',
            data: {
                action: 'del_announces',
                id: id,
            },

            beforeSend: function () {
                contentCard.addClass('deleting');
            },

            success: function (response) {
                contentCard.html(response);
                contentCard.fadeOut('fast');
            },
        });
    }

    jQuery('.content-card__delete-btn').on('click', function () {
        let contentCard = jQuery(this).siblings('.content-card');
        let id = contentCard.data('id');

        deleteAnnounces(id, contentCard);
    });
};

mena.main.init();
